@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #f47048; /* Default primary color */
}

body {
  font-family: "DM Sans", sans-serif !important;
}

.bg-primary-400-30 {
  background-color: hexToRgba var(--primary-color);
}

/* Scrollbar design */
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
}

/* Number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: gray;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #e1e1e1;
}

/* Checkbox */
input:checked ~ .checkmark {
  background-color: #f47048;
  border-color: #f47048;
}
input:checked ~ .checkmark:after {
  display: block;
}
/* Radio button */
input:checked ~ .radio-btn:after {
  display: block;
}
input:checked ~ .radio-btn {
  border: 2px solid #f47048;
}
input:checked ~ .color-radio-btn:after {
  display: block;
}
/* Date input */
.calendar input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  display: block;
  width: 40px;
  height: 30px;
}
@media (min-width: 1024px) {
  .calendar input[type="date"]::-webkit-calendar-picker-indicator {
    width: 48px;
    height: 38px;
  }
}
@media (min-width: 1280px) {
  .calendar input[type="date"]::-webkit-calendar-picker-indicator {
    height: 47px;
    width: 60px;
  }
}

/* Search input */
/* input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
} */

/* .table-container table {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
} */

.table-container th,
.table-container td {
  padding: 10px;
  padding-left: 0;
  border-bottom: 1px solid #eeeeee;
  text-align: left;
}

.table-container .pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  /* margin: 20px 0; */
}

.table-container .pagination li {
  margin: 0 5px;
}

.table-container .pagination a {
  display: block;
  padding: 8px 13px;
  border-radius: 4px;
  text-decoration: none;
  @apply bg-white border text-primary-400 border-secondary-200;
}

.table-container .pagination a:hover {
  @apply transition-all duration-150 ease-in-out bg-secondary-50;
}

.table-container .pagination .active a {
  @apply text-white bg-primary-400 border-primary-400;
}

.table-container .pagination .disabled a {
  pointer-events: none;
  @apply text-gray-500 bg-gray-200 border-gray-300;
}

@layer components {
  .my-react-select-container .my-react-select__control {
    @apply bg-secondary-50 min-h-[46px] min-w-[110px] border border-secondary-200 outline-none rounded-theme;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply shadow-none outline-none hover:border-secondary-200;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-white  py-0 !z-40; /* !min-w-48 */
  }

  .my-react-select-container .my-react-select__menu-list {
    @apply py-0 rounded-xs;
  }

  .my-react-select-container .my-react-select__option {
    @apply py-2 px-3 text-base bg-white text-secondary-600 hover:bg-primary-150 hover:text-primary-400 hover:font-medium;
  }

  .my-react-select-container .my-react-select__indicator-separator {
    @apply hidden bg-secondary-400;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-base text-black;
  }

  .my-react-select-container .my-react-select__multi-value {
    @apply bg-primary-400;
  }

  .my-react-select-container .my-react-select__multi-value__label {
    @apply text-white;
  }

  .my-react-select-container .my-react-select__multi-value__remove {
    @apply text-white hover:text-white hover:bg-primary-400;
  }
}

/* react select async paginate  */
@layer components {
  .my-react-select-async-paginate .react-select__control {
    @apply min-h-[47px] min-w-[110px] border border-gray-500 outline-none rounded-theme text-sm shadow-md font-semibold capitalize bg-white text-secondary-400;
  }

  .my-react-select-async-paginate .react-select__menu {
    @apply py-0 bg-white !z-40;
  }
  .my-react-select-async-paginate .react-select__control--is-focused {
    @apply shadow-none outline-none;
  }
  .my-react-select-async-paginate .react-select__menu-list {
    @apply py-0 rounded-xs;
  }

  .my-react-select-async-paginate .react-select__option {
    @apply p-3 text-base bg-white text-secondary-400 hover:bg-primary-150 hover:text-primary-400 hover:font-medium;
  }

  .my-react-select-async-paginate .react-select__indicator-separator {
    @apply hidden bg-secondary-400;
  }

  /* .my-react-select-async-paginate .react-select__option{
     @apply p-3 text-base bg-white text-secondary-600 hover:bg-primary-400 hover:text-white;
  } */
}

/* ------------- below config only per page change table styling ------------ */

@layer components {
  .my-react-select-container .table-react-select__control {
    @apply bg-secondary-50 min-h-[46px] border border-secondary-200 outline-none rounded-theme;
  }

  .my-react-select-container .table-react-select__control--is-focused {
    @apply shadow-none outline-none hover:border-secondary-200;
  }

  .my-react-select-container .table-react-select__menu {
    @apply py-0 bg-white border border-secondary-200 !z-40;
  }

  .my-react-select-container .table-react-select__menu-list {
    @apply py-0 rounded-xs;
  }

  .my-react-select-container .table-react-select__option {
    @apply p-3 text-base bg-white text-secondary-600 hover:bg-primary-400 hover:text-white;
  }

  .my-react-select-container .table-react-select__indicator-separator {
    @apply hidden bg-secondary-400;
  }

  .my-react-select-container .table-react-select__input-container,
  .my-react-select-container .table-react-select__placeholder,
  .my-react-select-container .table-react-select__single-value {
    @apply text-base text-black;
  }

  .my-react-select-container .table-react-select__multi-value {
    @apply bg-primary-400;
  }

  .my-react-select-container .table-react-select__multi-value__label {
    @apply text-white;
  }

  .my-react-select-container .table-react-select__multi-value__remove {
    @apply text-white hover:text-white hover:bg-primary-400;
  }
}

.checkbox-label-between div {
  @apply flex items-center justify-between;
}

/* --------------------------- custom own spinner --------------------------- */
.loader--ring {
  display: inline-block;
  position: relative;
}

.loader--ring div {
  display: block;
  position: absolute;
  animation: loader--ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 3px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  /* margin: 6px; */
}

.loader--ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader--ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader--ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.loader-container .loader--ring div {
  border-color: var(--primary-color) transparent transparent transparent;
}

@keyframes loader--ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.react-tel-input .form-control {
  @apply !w-full !min-h-[46px] !rounded-theme !text-info-400 !font-semibold;
}
.react-tel-input .flag-dropdown {
  @apply !rounded-l-theme;
}
.react-tel-input .flag-dropdown.open {
  @apply !rounded-l-theme;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag.open {
  @apply !overflow-hidden !bg-transparent;
}
.react-tel-input .selected-flag {
  @apply !rounded-theme;
}

.payment-table-container table tfoot {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 10;
}

@media print {
  .odd-page {
    display: none;
  }
}

.scrolling-text {
  display: inline-block;
  white-space: nowrap;
  animation: scroll-text 10s linear infinite;
}

@keyframes scroll-text {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.client_marquee_anim {
  animation: marqueeH 10s linear infinite;
}

@keyframes marqueeH {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}
